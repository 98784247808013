<template>
	<div class="page seriesDetail_page" v-if="dataobj">
		<div class="bannerbox animated">
			<el-carousel indicator-position="none" arrow="never" :autoplay="false" :height="screenHeight+'px'">
			    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
			       <div class="imgbox">
					   <!-- :poster="item.imageUrl" -->
					    <video v-show="item.videoUrl" :src="item.videoUrl" 
						class="video img100"
						id="video"
						ref="videoPlayer"
						style="object-fit: cover;"
						:loop = "true"
						:autoplay="true"
						muted
						playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video>
					    <img v-if="!item.videoUrl" class="img100" :src="item.imageUrl"/>
						<div class="titlebox animation_inUp">
						   <div class="text1 fontsize68" v-if="item.title">{{item.subtitle}} {{item.title}}</div>
						   <!-- <div class="text2 fontsize36" v-if="item.subtitle">{{item.subtitle}}</div> -->
						   <!-- <div v-if="item.redirectUrl.trim()" class="navicon" @click="handleSearche(item.redirectUrl)"><img class="img100" src="../assets/static/navto.png"/></div> -->
					    </div>
					</div>
			    </el-carousel-item>
			</el-carousel>
		</div>
		<div class="data_con1">
			<div class="w1200 box1">
				<div class="leftbox">
					<div class="text1 fontsize32">{{dataobj.title}}</div>
					<div class="text2 fontsize16 huanhang">{{dataobj.contents}}</div>
				</div>
				<div class="rightbox">
					<div class="imgvideo">
						<video v-show="dataobj.videoUrl" :src="dataobj.videoUrl"
						class="video img100"
						id="video"
						ref="videoPlayer"
						style="object-fit: cover;"
						:loop = "true"
						muted
						playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video>
						<img class="img100 hoverbig" :src="dataobj.imageUrl"/>
					</div>
				</div>
			</div>
			<!-- 特点 -->
			<div class="w1200 box2" v-for="(item,index) in dataList" :key="index">
				<div class="rightbox">
					<div class="imgvideo">
						<video v-show="item.videoUrl" :src="item.videoUrl"
						class="video img100"
						id="video"
						ref="videoPlayer"
						style="object-fit: cover;"
						:loop = "true"
						muted
						playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video>
						<img class="img100 hoverbig" :src="item.imageUrl"/>
					</div>
				</div>
				<div class="leftbox">
					<div class="text1 fontsize32">{{item.title}}</div>
					<div class="text2 fontsize16 huanhang">{{item.contents}}</div>
				</div>
			</div>
			<!-- 商品列表 -->
			<div class="w1200 box_goods" v-if="goodsList&&goodsList.length>0">
				<div class="btnslide">
					<div class="lefticon" @click="slideBox('left')">
						<img class="img100" src="../assets/static/Frame295.png"/>
					</div>
					<div class="righticon" @click="slideBox('right')">
						<img class="img100" src="../assets/static/Frame294.png"/>
					</div>
				</div>
				<div class="slidebox">
					<div class="slidelist">
						<!-- <div class="itemobj" v-for="(item,index) in goodsList" :key="index">
							<img class="img100 hoverbig" src="../assets/banner2.png"/>
							<div class="titlebox fontsize16 clamp2">产品名称产品名称产品名称产品名称产品名称产品名称产品名</div>
						</div> -->
						<swiper ref="mySwiper" class="swiper" :options="swiperOptions">
						    <swiper-slide v-for="(item, index) in goodsList" :key="index">
								<div class="itemobj pointer" @click="handleSearche(item.redirectUrl)">
									<img class="img100 hoverbig" :src="item.imageUrl"/>
									<div class="titlebox fontsize20 clamp2">{{item.title}}</div>
								</div>
							</swiper-slide>
						</swiper>
					</div>
				</div>
			</div>
			<!-- 商品列表 -->
			<div class="zizhi_box" v-if="productList&&productList.length>0">
				<div class="imgbg">
					<img class="img100" src="../assets/static/image1719.png"/>
				</div>
				<div class="w1200 titlebox">
					<div class="text1 fontsize32">产品资质与荣耀</div>
					<div class="text2 fontsize16">Product Qualification and Honor</div>
				</div>
				<div class="w1200 bannerlist">
					<el-carousel :interval="1800" type="card" indicator-position="none">
					    <el-carousel-item v-for="(item,index) in productList" :key="index">
					        <div class="itemobj">
					      	   <img class="img" :src="item.imageUrl"/>
							   <img class="mkimg" src="../assets/static/image28.png"/>
					        </div>
					    </el-carousel-item>
					</el-carousel>
					<!-- <swiper ref="mySwiper2" class="swiper2" :options="swiperOptions2">
					    <swiper-slide class="swiper-slide2" v-for="(item, index) in productList" :key="index">
							<div class="itemobj">
							   <img class="img" src="../assets/banner2.png"/>
							   <img class="mkimg" src="../assets/static/image28.png"/>
							</div>
						</swiper-slide>
					</swiper> -->
				</div>
			</div>
			
		</div>
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import { swiper,swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'//这里注意具体看使用的版本是否需要引入样式，以及具体位置。
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			screenHeight:1080,//默认
			uid:null,//uid
			dataobj:null,
			bannerList: [],
			dataList: [],//内容
			goodsList:[],
			productList:[],
			swiperOptions:{
				// 设置显示的元素数量
				direction: 'horizontal',
				slidesPerView: 4,
			},
			swiperOptions2:{
				slidesPerView: 7,
				spaceBetween: 0,
			},
		};
	},
	mounted: function() {
		this.screenHeight = window.innerHeight;
		let width = document.documentElement.clientWidth
		if (width < 1200) {
			this.swiperOptions.slidesPerView = 1
		}
		if(this.$route.query.uid){
			this.uid = Number(this.$route.query.uid)
			this.initFuc()
		}else{
			this.$message.error("参数丢失，请返回重试！")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	activated() {
	    console.log('Component A activated');
	},
	watch: {
		$route(to, from) {
			console.log(to)
			console.log(from)
			if(to.fullPath!=from.fullPath){
				this.$router.go(0)
			}
		},
	},
	methods: {
		//初始化
		async initFuc(){
			//详情
			this.getdetail()
			await this.getwheelPage(1,"content/ct-series-flavour/IndexProduct")
			await this.getwheelPage(2,"content/ct-series-product/IndexProduct")
			await this.getwheelPage(3,"content/ct-series-product/IndexHonour")
		},
		//跳转
		handleSearche(url){
			if(url&&url!='no'&&url!='No'&&url!='NO'){
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					const newUrl = url.split('?')[0]
					const params = this.parseString(url)
					this.$router.push({
						path: "/"+newUrl,
						query:params
					});
				}
			}else{
				console.log("无跳转路径")
				//this.$message.info("此模块暂未开放")
			}
		},
		//详情
		getdetail() {
			var _this = this
			var params = {
				id:this.uid
			}
			this.$http.post('content/ct-series-info/detail', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data
					if (dataobj.imgUrlList) {
						try {
							dataobj["imgUrlList"] = JSON.parse(dataobj.imgUrlList)
							dataobj["imageUrl"] = dataobj.imgUrlList[0]
						} catch (e) {
							dataobj["imgUrlList"] = []
							dataobj["imageUrl"] = ""
							// TODO handle the exception
						}
					}
					if(dataobj.videoUrl){
						_this.bannerList = [{
							videoUrl:dataobj.videoUrl,
							imageUrl:"",
							title:dataobj.title,
							subtitle:dataobj.subtitle
						}]
					}
					dataobj.imgUrlList.forEach((item,index)=>{
						_this.bannerList.push({
							videoUrl:null,
							imageUrl:item,
							title:dataobj.title,
							subtitle:dataobj.subtitle
						})
					})
					_this.dataobj = dataobj
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//滚动动画
		slideBox(type) {
			if(type=="left"){
				this.$refs.mySwiper.swiper.slidePrev()
			}else{
				this.$refs.mySwiper.swiper.slideNext()
			}
		   
		},
		//获取轮播图
		getwheelPage(type,conurl) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 18
				params["seriesId"] = _this.uid
				this.$http.post(conurl, params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							if(type==1){
								records.forEach((item,index)=>{
									if (item.imgUrlList) {
										try {
											item["imgUrlList"] = JSON.parse(item.imgUrlList)
											item["imageUrl"] = item.imgUrlList[0]
										} catch (e) {
											item["imgUrlList"] = []
											item["imageUrl"] = ""
											// TODO handle the exception
										}
									}
								})
								_this.dataList = records
							}else if(type==2){
								_this.goodsList = records
							}else if(type==3){
								_this.productList = records
							}else{
								
							}
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		
	}
};
</script>
<style lang="scss" scoped>
	.hoverbig:hover{
	    transform: scale(1.01);
	    transition: ease all 0.3s;
	}
	.swiper2 { width: 100%; height: 448px;}
	.swiper-slide2 { box-sizing: border-box; width: 338.526px !important; }
</style>
